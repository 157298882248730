import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../templetes/Layout';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';

const Home = () => {
    const navigate = useNavigate();
    const handleOpen = (eventNumber: string) => {
        const path = `/event`; // パスを見積り番号に基づいて設定
        navigate(path);
    };

    const rows = [
        {
            eventNumber: '001',
            templateNumber: '2024/12/12',
            templateName: '新宿区マンション',
        },
        {
            eventNumber: '002',
            templateNumber: '2024/12/12',
            templateName: '墨田区戸建',
        },
        {
            eventNumber: '003',
            templateNumber: '2024/12/12',
            templateName: '新宿区タワーマンション',
        },
        {
            eventNumber: '004',
            templateNumber: '2024/12/12',
            templateName: '新宿区事務所',
        },
        {
            eventNumber: '005',
            templateNumber: '2024/12/12',
            templateName: '渋谷区マンション',
        }
        // 他のデータ...
    ];

    const getStatusColor = (status: string) => {
        return status === '確定' ? 'blue' : 'red';
    };

    return (
        <Layout title="ダッシュボード">
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">見積り番号</TableCell>
                            <TableCell align="left">日付</TableCell>
                            <TableCell align="left">見積り内容</TableCell>
                            <TableCell align="center">確認</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell component="th" scope="row" align="left">
                                    {row.eventNumber}
                                </TableCell>
                                <TableCell align="left">{row.templateNumber}</TableCell>
                                <TableCell align="left">{row.templateName}</TableCell>
                                <TableCell align="center">
                                    <Button 
                                        variant="contained" 
                                        onClick={() => handleOpen(row.eventNumber)}
                                        sx={{ backgroundColor: '#3c71ac', '&:hover': { backgroundColor: '#325c85' } }}
                                    >
                                        開く
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Layout>
    );
};

export default Home;
