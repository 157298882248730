// App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './component/pages/Home';
import SENT from './component/pages/sent';
import NotSent from './component/pages/not_sent';
import Template001 from './component/pages/001';
import Template002 from './component/pages/002';
import LoginPage from './component/pages/LoginPage';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './component/PrivateRoute';

export default function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route path='/login' element={<LoginPage />} />
          <Route 
            path='/' 
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            } 
          />
          <Route path="/sent"  element={<SENT /> } />
          <Route path="/notsent"  element={<NotSent /> } />
          <Route path="/event"  element={<Template001 /> } />
          <Route path="/not_event"  element={<Template002 /> } />
          {/* ...他のプライベートルート... */}
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}
