import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // useNavigateをインポート
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';

const SideMenu: React.FC = () => {
    const [openAdmin, setOpenAdmin] = useState(true);
    const [openVerification, setOpenVerification] = useState(true);
    const navigate = useNavigate(); // useNavigateフックの使用

    const handleClickAdmin = () => {
        setOpenAdmin(!openAdmin);
    };

    const handleClickVerification = () => {
        setOpenVerification(!openVerification);
    };

    const handleNavigation = (path: string) => () => { // ナビゲーション関数
        navigate(path);
    };

    return (
        <List component="nav">
            <ListItemButton
                onClick={handleClickAdmin}
                sx={{
                    backgroundColor: '#2196d5',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: '#2196d5' // ホバー効果が無効化されます
                    }
                }}
            >
                <ListItemIcon sx={{ color: 'inherit' }}>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="管理" />
                {openAdmin ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openAdmin} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }} onClick={handleNavigation('/')}>
                        <ListItemIcon>
                            <PeopleIcon />
                        </ListItemIcon>
                        <ListItemText primary="ダッシュボード" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} onClick={handleNavigation('/sent')}>
                        <ListItemIcon>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText primary="見積り未送付一覧" />
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} onClick={handleNavigation('/notsent')}>
                        <ListItemIcon>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText primary="送付済み見積り一覧" />
                    </ListItemButton>
                </List>
            </Collapse>
        </List>
    );
};

export default SideMenu;
