import React, { useState } from 'react';
import Layout from '../templetes/Layout';
import { 
  TextField, Button, Box, Typography, Paper, Grid, FormControlLabel, Checkbox, Select, MenuItem, InputLabel, FormControl
} from '@mui/material';

const Template001: React.FC = () => {

  return (
    <Layout title="見積り依頼者確認画面">
      <Paper style={{ padding: 16 }}>
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          ■ 候補日
        </Typography>
        ＊＊＊＊＊＊＊＊
        <hr />
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          ■ 希望時間
        </Typography>
        ＊＊＊＊＊＊＊＊
        <hr />
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          ■ 現在の住所
        </Typography>
        ＊＊＊＊＊＊＊＊
        <hr />
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          ■ 引越し先住所
        </Typography>
        ＊＊＊＊＊＊＊＊
        <hr />
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          ■ 立ち寄りの有無
        </Typography>
        ＊＊＊＊＊＊＊＊
        <hr />
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          ■ 立ち寄り先住所
        </Typography>
        ＊＊＊＊＊＊＊＊
        <hr />
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          ■ 立ち寄り先で積みたい降ろしたい荷物
        </Typography>
        ＊＊＊＊＊＊＊＊
        <hr />
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          ■ 荷物
        </Typography>
        ＊＊＊＊＊＊＊＊
        <hr />
        {/* Add Upload Form */}
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          ■ 見積り書の添付
        </Typography>
        <Box>
          <TextField
            type="file"
            fullWidth
            variant="outlined"
            onChange={(e) => {
              const target = e.target as HTMLInputElement; // EventTarget を HTMLInputElement にキャスト
              const file = target.files ? target.files[0] : null;
              console.log(file); // ここでファイルを扱う
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => console.log('File uploaded')} // 実際のファイルアップロードロジックをここに追加する
            style={{ marginTop: '20px' }}
          >
            添付する
          </Button>
        </Box>

      </Paper>
    </Layout>
  );
};

export default Template001;
